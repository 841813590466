<template>
  <div v-if="reports" class="page-wrapper">
    <h2 class="text-h5">
      {{ partner.name }} reports
    </h2>
    <div v-for="(report) in reports" :key="report.name" class="reports-wrapper">
      <h3 class="text-h6">
        {{ report.name }}
      </h3>
      <p class="text-capitalize text-italic">
        {{ report.delivery_frequency }}
      </p>
      <p>{{ report.description }}</p>
      <div class="form">
        <recipient-input
          v-for="(recipient, recipientIndex) in report.recipients"
          :key="recipientIndex"
          :recipient="recipient"
          :recipient-index="recipientIndex"
          :report-id="report.id"
          class="recipient-wrapper"
          @updated="getPartnerReports"
        />
        <form
          class="recipient-wrapper"
          @submit.prevent="addReportRecipient(report.id)"
        >
          <label :for="`${report.id}-new`">
            New recipient
          </label>
          <input :id="`${report.id}-new`" v-model="newReportRecipient[report.id]" type="email">
          <button class="add" :disabled="!newReportRecipient[report.id]" type="submit">
            Add
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getReports, postReportRecipient } from 'api/partner'
import { handleErrors } from 'utils/utils'
import recipientInput from './recipient-input.vue'
import loading from 'utils/loading'

export default {
  name: 'PartnerConfig',
  components: { recipientInput },
  data () {
    return {
      reports: null,
      newReportRecipient: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    })
  },
  async mounted () {
    loading.start({
      partnerSlug: this.partner.slug,
      spinnerColor: this.partner.hex,
      message: 'Loading partner reports...'
    })
    await this.getPartnerReports()
    loading.stop()
  },
  methods: {
    async getPartnerReports () {
      try {
        const { data } = await getReports(this.partner.slug)
        this.reports = data
      } catch (err) {
        handleErrors(err)
      }
    },
    async addReportRecipient (reportId) {
      this.loading = true
      try {
        await postReportRecipient(reportId, this.newReportRecipient[reportId])
        this.getPartnerReports()
        this.newReportRecipient = {}
        this.$q.notify({
          color: 'positive',
          message: 'Recipient added',
          icon: 'fas fa-check'
        })
      } catch (err) {
        handleErrors(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper
  padding 16px 32px

h3
  margin 0

.reports-wrapper
  margin 16px auto
  max-width 762px

.form
  display flex
  flex-direction column
  width 100%

.recipient-wrapper
  display grid
  grid-template-columns 140px auto repeat(2, 88px)
  width 100%

label
  display flex
  align-items center
  flex-grow 1

input
  border 1px solid black
  flex-grow 1
  margin 8px
  margin-inline-start 16px
  padding 4px 8px

button
  cursor pointer
  border none
  color white
  border-radius 4px
  padding 4px 8px
  margin 8px 4px
  width 80px
  &.add
    width 168px
    background-color var(--q-color-primary)
    outline-color var(--q-color-primary)
    grid-column span 2
  &:focus
    outline-style double
    outline-width 4px
</style>
