import http from './http'

export function get (partner) {
  return http.get(`/partner/${partner}`)
}

export function patch (partner, body) {
  return http.patch(`partner/${partner}`, body)
}

export function getReports (partner) {
  return http.get(`/partner/${partner}/reports`)
}

export function putReportRecipient (reportId, recipientId, email) {
  return http.put(`/reports/${reportId}/recipients/${recipientId}`, { email })
}

export function deleteReportRecipient (reportId, email) {
  return http.delete(`/reports/${reportId}/recipients?recipient=${email}`)
}

export function postReportRecipient (reportId, email) {
  return http.post(`/reports/${reportId}/recipients`, { email })
}
