<template>
  <form @submit.prevent="updateReportRecipient(reportId, id, email)">
    <label :for="`${reportId}-${recipient.id}`">
      Report recipient {{ recipientIndex + 1 }}
    </label>
    <input
      :id="`${reportId}-${recipient.id}`"
      v-model="email" type="email"
      placeholder="Enter an email address"
      :disabled="loading"
      :class="{ error: errors }"
    >
    <button
      type="submit"
      class="update"
      :disabled="loading"
    >
      Update
    </button>
    <button
      class="delete"
      :disabled="loading"
      @click="removeReportRecipient(reportId, email)"
    >
      Remove
    </button>
    <div v-for="[error] in errors" :key="error" class="errors">
      {{ error }}
    </div>
  </form>
</template>

<script>
import { putReportRecipient, deleteReportRecipient } from 'api/partner'
import { handleErrors } from 'utils/utils'

export default {
  name: 'RecipientInput',
  props: {
    recipient: Object,
    recipientIndex: Number,
    reportId: Number
  },
  data () {
    return {
      email: null,
      id: null,
      loading: false,
      errors: null
    }
  },
  watch: {
    recipient: {
      deep: true,
      immediate: true,
      handler (val) {
        this.email = val.email
        this.id = val.id
      }
    }
  },
  methods: {
    async removeReportRecipient (reportId, email) {
      this.loading = true
      try {
        await deleteReportRecipient(reportId, email)
        this.$emit('updated')
        this.$q.notify({
          color: 'positive',
          message: 'Recipient removed',
          icon: 'fas fa-check'
        })
      } catch (err) {
        handleErrors(err)
      } finally {
        this.loading = false
      }
    },
    async updateReportRecipient (reportId, recipientId, email) {
      this.errors = null
      this.loading = true
      try {
        await putReportRecipient(reportId, recipientId, email)
        this.$emit('updated')
        this.$q.notify({
          color: 'positive',
          message: 'Recipient updated',
          icon: 'fas fa-check'
        })
      } catch (err) {
        if (err?.data?.errors) {
          this.errors = err.data.errors
        } else {
          handleErrors(err)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

label
  display flex
  align-items center
  flex-grow 1

input
  border 1px solid black
  flex-grow 1
  margin 8px
  margin-inline-start 16px
  padding 4px 8px
  &.error
    border 2px solid red
    margin 7px
    margin-inline-start 15px

button
  cursor pointer
  border none
  color white
  border-radius 4px
  padding 4px 8px
  margin 8px 4px
  width 80px
  &.update
    background-color var(--q-color-positive)
    outline-color var(--q-color-positive)
  &.delete
    background-color var(--q-color-negative)
    outline-color var(--q-color-negative)
  &:focus
    outline-style double
    outline-width 4px

.errors
  grid-column 2 / span 3
  color red
  margin-inline-start 16px
</style>
